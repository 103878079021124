import * as React from "react"
import { graphql } from 'gatsby';

import Layout from "../components/layout"
import Seo from "../components/seo"

// The bridge for Gatsby Background Image in V3
import { BgImage } from 'gbimage-bridge'

// All the gatsby-plugin-image goodness
import { StaticImage, getImage } from "gatsby-plugin-image"

import { Carousel, Container } from "react-bootstrap"

const SecondPage = ({data}) => (
  <Layout>
    <BgImage 
      image={getImage(data.indexImage)} 
      className="masthead"
    >
       <div className="black-overlay">
            <div className="content-box">
              <h1>Neighborhood</h1>
            </div>
          </div>
    </BgImage>

    <Container>
          <h2 className="summary">Historic Downtown Brunswick</h2>
          <p className="summary">All of The Wick locations are conveniently located in the heart of Historic Downtown Brunswick. The downtown area is filled with great retail shops, restaurants, art galleries, professional services, and entertainment. Experience some of Brunswick's notable sites and events including: Signature Square Parks, Historic Old City Hall, Lover’s Oak, Mary Ross Waterfront Park, Historic Ritz Theatre, Brunswick Landing Marina, First Fridays, and much more!
          </p>
          
        </Container>
        <Container className="singleCol">
        <hr />
        <Carousel autoPlay interval={80000} touch={true}>
          <Carousel.Item>
            <StaticImage
              src="../images/neighborhood/neighborhood-01.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="The Neighborhood"
              className="d-block w-100"
            />
          </Carousel.Item>

          <Carousel.Item>
            <StaticImage
              src="../images/neighborhood/neighborhood-02.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="The Neighborhood"
              className="d-block w-100"
            />
          </Carousel.Item>

          <Carousel.Item>
            <StaticImage
              src="../images/neighborhood/neighborhood-03.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="The Neighborhood"
              className="d-block w-100"
            />
          </Carousel.Item>

          <Carousel.Item>
            <StaticImage
              src="../images/neighborhood/neighborhood-04.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="The Neighborhood"
              className="d-block w-100"
            />
          </Carousel.Item>

          <Carousel.Item>
            <StaticImage
              src="../images/neighborhood/neighborhood-05.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="The Neighborhood"
              className="d-block w-100"
            />
          </Carousel.Item>

          <Carousel.Item>
            <StaticImage
              src="../images/neighborhood/neighborhood-06.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="The Neighborhood"
              className="d-block w-100"
            />
          </Carousel.Item>
          </Carousel>
          
        </Container>




  </Layout>
)

export const Head = () => <Seo title="Page two" />

export default SecondPage

export const query = graphql`
  query {
    indexImage: file(relativePath: {eq: "neighborhood.jpg"}) {
      id
      base
      relativePath
      childImageSharp {
        gatsbyImageData(
          width: 2000
          webpOptions: {quality: 30}
          placeholder: BLURRED
          blurredOptions: {width: 30}
        )
      }
    }
  }
`